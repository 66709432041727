import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { dateTimeMaskTimestamp } from 'app/utils/constants';
import { Cargo, Container, Order, TempRangeStatus } from 'app/utils/dataTypes';
import TemperatureChart from 'app/components/TemperatureChart';
import { encodeObjToQuery, trimSensorData } from 'app/utils/tools';
import LoadingSkyCell from 'app/components/LoadingSkyCell';
import useSecureBackendEndpoints from 'app/hooks/useSecureBackendEndpoints';
import moment from 'moment';
import dateToISO from 'app/utils/dateToISO';
import MeasurementCard from '../MeasurementCard';
import ShipmentCard from '../ShipmentCard';
import useStyles from '../TemperatureInfo.style';
import TempRange from '../../../../shared-components/TempRange';
import MeasuredTemperatureSwitch from '../MeasuredTemperatureSwitch';

const dateRanges = {
    '24h': 1,
    '7d': 7,
    '14d': 14,
};

type Props = {
    order: Order,
    serialNumber: string,
    cargo: Cargo,
    buttonPressed: string,
    setSensorDataLoading: any,
    sensorDataLoading: boolean,
    expectedLease: any,
    setContainerError: any,
    shipmentId: number,
    cargoId: number,
}

const TemperatureInfoContents = ({
    serialNumber,
    cargo,
    order,
    buttonPressed,
    setSensorDataLoading,
    sensorDataLoading,
    expectedLease,
    setContainerError,
    shipmentId,
    cargoId,
}: Props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [container, setContainer] = useState<Container>(null);
    const [sensorData, setSensorData] = useState([]);
    const [labelData, setLabelData] = useState(null);
    const [splitMeasurementsAndPredictions, setSplitMeasurementsAndPredictions] = useState(true);
    const {
        FlexibleRequest: apiRequest,
    } = useSecureBackendEndpoints('').requests;

    const [fromDate, toDate] = useMemo<(string | null)[]>(() => {
        if (buttonPressed === 'SHIPMENT') {
            return [
                sensorData?.[0]?.t,
                sensorData?.[sensorData?.length - 1]?.t,
            ];
        } else {
            return [
                dateToISO(moment().utc(false).subtract({ day: dateRanges[buttonPressed] })),
                dateToISO(moment().utc(false)),
            ];
        }
    }, [
        buttonPressed,
        sensorData,
    ]);

    useEffect(() => {
        if (shipmentId && cargoId) {
            (async () => {
                try {
                    const containerData = await apiRequest(
                        'GET',
                        `v2/shipments/${shipmentId}/cargo/${cargoId}`,
                    );

                    if (containerData) {
                        setContainer(containerData?.data);
                    } else {
                        setContainerError(true);
                    }
                } catch (error) {
                    global.console.log(error);
                    setContainer(null);
                    setContainerError(true);
                }
            })();
        } else {
            setContainer(null);
        }
    }, [serialNumber]);

    useEffect(() => {
        if (buttonPressed === 'NOW') return;
        if (!serialNumber) return;

        (async () => {
            setSensorDataLoading(true);
            let result;

            if (buttonPressed === 'SHIPMENT') {
                try {
                    result = await apiRequest(
                        'GET',
                        `v2/shipments/${shipmentId}/cargo/${cargoId}/sensor-data?${encodeObjToQuery({
                            splitMeasurementsAndPredictions,
                        })}`,
                    );
                } catch (e) {
                    setSensorDataLoading(false);
                }
            } else {
                try {
                    result = await apiRequest(
                        'GET',
                    `/packagings/${serialNumber}/sensor-data?${encodeObjToQuery({
                        from: fromDate,
                        to: toDate,
                        dataTypes: ['TEMPERATURE', 'DOOR'],
                        positions: ['INTERNAL'],
                    })}`,
                    );
                } catch (e) {
                    setSensorDataLoading(false);
                }
            }

            setSensorData(result?.data?.data || []);
            setLabelData({
                positions: result?.data?.positions,
                dataTypes: result?.data?.dataTypes,
                loggerTypes: result?.data?.loggerTypes,
            });
            setSensorDataLoading(false);
        }
        )();
    }, [
        buttonPressed,
        order,
        setSensorDataLoading,
        splitMeasurementsAndPredictions,
    ]);

    const updatedSensorData = useMemo(() => {
        return trimSensorData({
            sensorData,
            leaseStartTimestamp: order?.shipmentStart,
            leaseEndTimestamp: order?.shipmentEnd,
            baseLeaseUntil: order?.shipmentEndExpected,
            shipmentEnd: order?.shipmentEnd,
            from: fromDate,
            to: toDate,
        });
    }, [
        sensorData,
        buttonPressed,
        order?.shipmentStart,
        order?.shipmentEnd,
        order?.shipmentEndExpected,
        fromDate,
        toDate,
    ]);

    const tempCheck = useMemo(() => {
        return (cargo?.lastMeasuredData?.temperatureInternal > cargo
            ?.temperatureCheckResult?.temperatureRangeMax
        || cargo?.lastMeasuredData?.temperatureInternal < cargo
            ?.temperatureCheckResult?.temperatureRangeMin)
            ? TempRangeStatus.EXCURSION
            : !cargo?.lastMeasuredData?.temperatureInternal ? TempRangeStatus.NO_DATA : TempRangeStatus.IN_RANGE;
    }, []);

    const handleSplitMeasurementsAndPredictions = useCallback(() => {
        setSplitMeasurementsAndPredictions((prev) => !prev);
    }, []);

    const temperatureIndex = useMemo(() => (
        labelData?.dataTypes?.findIndex((dt, i) => dt === 'TEMPERATURE'
        && labelData?.positions?.[i] === 'INTERNAL')
    ), [labelData]);

    if (sensorDataLoading) {
        return (
            <div style={{ height: '100%' }}>
                <LoadingSkyCell />
            </div>
        );
    }

    return (
        <>
            {
                !container && <LoadingSkyCell />
            }
            {
                buttonPressed !== 'SHIPMENT' ? (
                    <div className={classes.topScreenWrapper}>
                        <div className={classes.topScreen}>
                            <span className={classes.containerText}>{t('CONTAINER')}</span>
                            <span className={classes.serialNumber}>{serialNumber}</span>
                            <span><TempRange tempRange={order?.temperatureRange} size={16} /></span>
                        </div>
                    </div>
                ) : (
                    <div className={classes.shipmentCardWrapper}>
                        <ShipmentCard
                            serialNumber={serialNumber}
                            shipmentNumber={order?.externalId}
                            selectedAsset={cargo}
                            tempCheckResponse={cargo?.temperatureCheckResult?.temperatureStatus}
                            buttonPressed={buttonPressed}
                            container={container}
                            fromDate={fromDate}
                            toDate={toDate}
                            temperatureRange={order.temperatureRange}
                            fromTooltip={
                                (order?.shipmentStart && 'Shipment Start Date')
                                || 'No Date'
                            }
                            toTooltip={
                                (order?.shipmentEnd && 'Shipment End Date')
                                || 'Expected Lease End Date'
                            }
                        />
                        <MeasuredTemperatureSwitch
                            value={splitMeasurementsAndPredictions}
                            onChange={handleSplitMeasurementsAndPredictions}
                            disabled={sensorData?.length === 0}
                            className={classes.measuredTemperatureSwitch}
                        />
                    </div>
                )
            }
            { !['SHIPMENT'].includes(buttonPressed) && <div style={{ flex: 1 }} />}
            {
                (buttonPressed === 'NOW' && container)
                    ? (
                        <MeasurementCard
                            tempRangeCheck={tempCheck}
                            lastMeasuredTempInternal={Number(cargo?.lastMeasuredData?.temperatureInternal?.toFixed(1))}
                            lastMeasuredTempInternalTimestamp={cargo?.lastMeasuredData?.temperatureInternalTimestamp}
                        />
                    ) : null
            }
            {
                sensorData?.length === 0
                && <div style={{ flex: !['SHIPMENT'].includes(buttonPressed) ? 1 : 'unset', height: '40px' }} />
            }
            {
                ['SHIPMENT'].includes(buttonPressed)
                && (sensorData?.length === 0
                    ? (
                        <div
                            className={classes.noSensorData}
                        >
                            <span className="title">{t('NO_DATA_AVAILABLE')}</span>
                            <span className="description">{t('NO_TEMPERATURE_DATA')}</span>
                            <span className="description">
                                <br />
                            </span>
                            <span className="description">{t('CHECK_AGAIN_LATER')}</span>
                        </div>
                    )
                    : temperatureIndex !== -1
                        ? (
                            <TemperatureChart
                                labelData={labelData}
                                data={updatedSensorData}
                                containerType={order?.temperatureRange}
                                chartMounted={() => setSensorDataLoading(false)}
                                excursionOn={cargo?.skyMindProductRelease?.temperatureExcursionOn}
                                shipmentStart={buttonPressed === 'SHIPMENT' ? fromDate : order?.shipmentStart}
                                shipmentEnd={buttonPressed === 'SHIPMENT' ? toDate : order?.shipmentEnd}
                                expectedLease={buttonPressed === 'SHIPMENT'
                                    ? expectedLease?.format(dateTimeMaskTimestamp) : null}
                            />
                        )
                        : (
                            <div
                                className={classes.noMeasuredData}
                            >
                                <span className="title">{t('NO_MEASURED_DATA_AVAILABLE')}</span>
                                <span className="description">{t('NO_MEASURED_DATA_DURING_SHIPMENT')}</span>
                                <span className="description">
                                    <br />
                                </span>
                                <span className="description">{t('SWITCH_OFF_MEASURED_TEMPERATURE_ONLY')}</span>
                            </div>
                        )
                )
            }
            {<div style={{ flex: 1 }} />}

        </>
    );
};

export default TemperatureInfoContents;
